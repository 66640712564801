import { Children, Component, ErrorInfo, ReactNode } from "react";

import cookie from "react-cookies";
import rg4js from "raygun4js";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    let children: string[] = [];
    Children.forEach(this.props.children, (child: any) => {
      if (typeof child?.type === "function") {
        children.push(child?.type?.name);
      }
    });
    if (process.env.REACT_APP_BUILD_TYPE === "production") {
      rg4js("logContentsOfXhrCalls", true);
      rg4js("setVersion", `MyNesting ${process.env.REACT_APP_BUILD_TYPE}`);
      rg4js("send", {
        error: "Error Boundary - " + children.join(", "),
        customData: [
          { errorMessage: error.message },
          { errorInfo: errorInfo },
          { errorStack: error.stack },
        ],
        tags: [
          "error_boundary",
          (cookie.load("DARK-MODE") === "true"
            ? "dark_theme"
            : "light_theme") ?? "light_theme",
        ],
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex align-items-center">
          <h6 className="m-0 me-3">Sorry.. there was an error</h6>
          <button
            className="btn btn-sm btn-outline-secondary py-0"
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import {
  ParametricPartDefinition,
  ParametricPartType,
  ParametricPartsRequest,
} from "../../../serviceClient/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ShapeClass, getOrderedDimensions } from "./CreateShapesDefinitions";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../../common/commonTypes";
import { RootState } from "../../../app/store";
import { addOnTheFlyPartsToNest } from "../../nesting/nestingSlice";

type CreateShapeState = {
  createShapesModalVisibility: boolean;
  parametricPartsRequestLoading: Loading;
  shapesTab: ParametricPartType;
  shapesTable: ShapeClass[];
};

const initialState: CreateShapeState = {
  createShapesModalVisibility: false,
  parametricPartsRequestLoading: "idle",
  shapesTab: ParametricPartType.RectangleXY,
  shapesTable: [],
};

export const parametricPartsRequest = createAsyncThunk(
  "parametricParts/request",
  async (_, thunkAPI) => {
    const parts = (thunkAPI.getState() as RootState).shapes.shapesTable;
    const filteredEmptyShapes = parts.filter((shape) => shape.isEmptyShape());

    let errors: boolean[] = [];
    filteredEmptyShapes.forEach((shape) => {
      errors.push(shape.submitValidation());
    });

    if (errors.includes(true)) {
      return thunkAPI.rejectWithValue("Validation failed");
    }

    const formattedParts = filteredEmptyShapes.map((shape) => {
      const part = new ParametricPartDefinition();
      part.name = shape.name;
      part.type = shape.type;
      part.orderedDimensions = getOrderedDimensions(shape);
      part.quantity = Number(shape.quantity);
      return part;
    });

    const payload = new ParametricPartsRequest();
    payload.parts = formattedParts;

    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(new ParametricPartsRequest(payload))
          .then((response) => {
            thunkAPI.dispatch(addOnTheFlyPartsToNest(response));
            thunkAPI.dispatch(setShapesTable([]));
            thunkAPI.dispatch(setCreateShapesModalVisibility(false));

            return response;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const createShapeSlice = createSlice({
  name: "createShape",
  initialState,
  reducers: {
    setCreateShapesModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.createShapesModalVisibility = action.payload;
    },
    setShapesTab: (state, action: PayloadAction<ParametricPartType>) => {
      state.shapesTab = action.payload;
    },
    setShapesTable: (state, action: PayloadAction<ShapeClass[]>) => {
      state.shapesTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //POST: request to create shapes
      .addCase(parametricPartsRequest.pending, (state) => {
        state.parametricPartsRequestLoading = "pending";
      })
      .addCase(parametricPartsRequest.fulfilled, (state) => {
        state.parametricPartsRequestLoading = "succeeded";
      })
      .addCase(parametricPartsRequest.rejected, (state) => {
        state.parametricPartsRequestLoading = "failed";
      });
  },
});

export const { setCreateShapesModalVisibility, setShapesTab, setShapesTable } =
  createShapeSlice.actions;

export default createShapeSlice.reducer;

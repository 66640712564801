import { AppThunk, RootState } from "../../app/store";
import {
  CancelNestingSession,
  CancelNestingSessionResponse,
  ExportRequest,
  ExportType,
  FetchNestingResult,
  FolderSearchType,
  GeometryForSheetRequest,
  GetPartGeometryForIds,
  JobStatus,
  Multiplicity,
  NestResultSummary,
  NestingPart,
  NestingProject,
  NestingProjectStartNest,
  NestingSetting,
  NestingSheet,
  PartGeometries,
  RotateOrMirrorParts,
  SaveNestingProject,
  SearchedLibraryPart,
  SheetGeometrySettings,
  UserNestingSessionSettingsRequest,
  UserNestingSessionSettingsResponse,
} from "../../serviceClient/api.dtos";
import {
  Dispatch,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  MeasurementUnits,
  measurementUnitShortString,
} from "../importer/importerTypes.dtos";
import {
  NestingProjectRunResponse,
  RestoreExistingProject,
  SetNestingAuth,
} from "./../../serviceClient/api.dtos";
import { clearNotes, fetchNotesForProject } from "../notes/notesSlice";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./../common/commonTypes";
import { PartialDeep } from "type-fest";
import { PublicClientApplication } from "@azure/msal-browser";
import ServerEventsNesting from "../../serviceClient/ESServiceClient/SeverEventsNesting";
import ServerEventsReport from "../../serviceClient/ESServiceClient/ServerEventsReport";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { fetchRecentNests } from "./../nests/nestsSlice";
import { fetchSavedNests } from "../nests/nestsSlice";
import { fetchUserSettings } from "./../settings/settingsSlice";
import { guid } from "../../Util";
import { t } from "i18next";

export enum NestingProgressState {
  Active,
  AllowanceReached,
  AwaitingWorker,
  Cancelled,
  Error,
  Idle,
  Uploading,
}

export enum ReportProgressState {
  Active,
  ReportGenerated,
  ReportFailed,
  Error,
  Idle,
  Pending,
  DownLoading,
  Downloaded,
  Connected,
}

export enum ActiveTab {
  Part,
  Nest,
  Notes,
}

type NestingState = {
  activeTab: ActiveTab;
  busyFetchingSettings: boolean;
  busySaving: boolean;
  cancelNestingSessionErrorMessage: string;
  cancelNestingSessionLoading: Loading;
  cancelNestingSessionResponse: CancelNestingSessionResponse;
  currentNestSaved: boolean;
  currentSettingsHasBeenNested: boolean;
  disableSaveAndNest: boolean;
  existingProjectId: string;
  exportNestingSessionErrorMessage: string;
  exportNestingSessionLoading: Loading;
  hoverPartId: string;
  invalidSheetIds: string[];
  isExampleProject: boolean;
  nestName: string;
  nestingActive: boolean;
  nestingFolderModalVisibility: boolean;
  nestingProgress: NestingProgressState;
  nestingResult: (NestResultSummary & { svg?: string }) | null;
  nestingResultErrorMessage: string;
  nestingResultLoading: Loading;
  nestingSessionProjectErrorMessage: string;
  nestingSessionProjectLoading: Loading;
  nestingSessionProjectResponse: NestingProject;
  nestingSessionRequestErrorMessage: any;
  nestingSessionRequestLoading: Loading;
  nestingSessionRequestResponse: NestingProjectRunResponse;
  partGeometryForIdsLoading: Loading;
  partsToNest: NestablePart[];
  previewError: string;
  previewLoading: Loading;
  previewSVG: string;
  reportProgress: ReportProgressState;
  restoreProjectError: string;
  restoreProjectLoading: Loading;
  runId: string;
  selectedPartIds: string[];
  selectedSheetIds: string[];
  sessionDirty: boolean;
  sessionId: string;
  sheetGeometryForSettingsLoading: Loading;
  sheetInputFocus: boolean;
  sheets: NestableSheet[];
  userNestingSessionSettings: NestingSetting[];
  userNestingSessionSettingsErrorMessage: string;
  userNestingSessionSettingsLoading: Loading;
  webSocketsError: string | null;
  reportExportProgressBarVisibility: boolean;
  reportExportTotal: number;
  reportExportLoaded: number;
};

export type NestablePart = SearchedLibraryPart &
  NestingPart & {
    active: boolean;
    busy: boolean;
    id: string;
    isDeleted: boolean;
    isFromSheet: boolean;
    isSelected: boolean;
    localReference: string;
    svg: string;
    unPlaced: number;
  };

export type NestableSheet = NestingSheet & {
  id: string;
  isFromSheet: boolean;
  preview: string;
  svg: string;
  localReference: string;
};

const initialState: NestingState = {
  activeTab: ActiveTab.Part,
  busyFetchingSettings: false,
  busySaving: false,
  cancelNestingSessionErrorMessage: "",
  cancelNestingSessionLoading: "idle",
  cancelNestingSessionResponse: new CancelNestingSessionResponse(),
  currentNestSaved: false,
  currentSettingsHasBeenNested: false,
  disableSaveAndNest: false,
  existingProjectId: "",
  exportNestingSessionErrorMessage: "",
  exportNestingSessionLoading: "idle",
  hoverPartId: "",
  invalidSheetIds: [],
  isExampleProject: false,
  nestName: "",
  nestingActive: false,
  nestingFolderModalVisibility: false,
  nestingProgress: NestingProgressState.Idle,
  nestingResult: null,
  nestingResultErrorMessage: "",
  nestingResultLoading: "idle",
  nestingSessionProjectErrorMessage: "",
  nestingSessionProjectLoading: "idle",
  nestingSessionProjectResponse: new NestingProject(),
  nestingSessionRequestErrorMessage: "",
  nestingSessionRequestLoading: "idle",
  nestingSessionRequestResponse: new NestingProjectRunResponse(),
  partGeometryForIdsLoading: "idle",
  partsToNest: [],
  previewError: "",
  previewLoading: "idle",
  previewSVG: "",
  reportProgress: ReportProgressState.Idle,
  restoreProjectError: "",
  restoreProjectLoading: "idle",
  runId: "",
  selectedPartIds: [],
  selectedSheetIds: [],
  sessionDirty: false,
  sessionId: "",
  sheetGeometryForSettingsLoading: "idle",
  sheetInputFocus: false,
  sheets: [],
  userNestingSessionSettings: [],
  userNestingSessionSettingsErrorMessage: "",
  userNestingSessionSettingsLoading: "idle",
  webSocketsError: null,

  reportExportProgressBarVisibility: false,
  reportExportTotal: 0,
  reportExportLoaded: 0,
};

export const rotateOrMirrorParts = createAsyncThunk(
  "nesting/rotateOrMirrorParts",

  async (
    { parts, mirror, rotation }: Partial<RotateOrMirrorParts>,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(
            new RotateOrMirrorParts({
              parts,
              mirror,
              rotation,
            })
          )
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchPartGeometryForIds = createAsyncThunk(
  "nesting/fetchPartGeometryForIds",

  async (ids: string[], thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .get(new GetPartGeometryForIds({ ids }))

          .then((result: PartGeometries[]) => {
            return result;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchSheetGeometryForSettings = createAsyncThunk(
  "nesting/fetchSheetGeometryForSettings",

  async (sheetSettings: SheetGeometrySettings[], thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(new GeometryForSheetRequest({ sheetSettings }))
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchSVG = createAsyncThunk(
  "nesting/fetchSVG",

  async (svgUrl: string, thunkAPI) => {
    const { getTokenRedirect } = thunkAPI.extra as {
      getTokenRedirect(msalInstance: PublicClientApplication): Promise<void | {
        token: string;
        isFromCache: boolean;
      }>;
    };
    return await getTokenRedirect(window.MsalInstance)
      .then(async (response) => {
        if (response === undefined) {
          throw new Error("No token");
        }

        return await fetch(svgUrl, {
          headers: {
            authorization: `Bearer ${response.token}`,
          },
        })
          .then((response) => {
            if (response.ok) {
              const text = response.text();

              return text;
            } else {
              // 4xx and 5xx errors -  when the  token fails
              throw new Error(response.statusText);
            }
          })
          .then((svg) => {
            if (svg !== undefined) {
              return svg as string;
            } else {
              return thunkAPI.rejectWithValue("No SVG");
            }
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const startExport =
  (type: ExportType): AppThunk =>
  async (dispatch: Dispatch, getState) => {
    dispatch(setReportProgress(ReportProgressState.Pending));

    const { nesting: nestingState } = getState() as RootState;

    ///start signalR connection
    const serverEvents = new ServerEventsReport();

    //Attach instance to window so we can abort elsewhere
    window.ServerEventReportInstance = serverEvents;

    serverEvents.setDispatch(dispatch);
    serverEvents.setGetState(getState);
    serverEvents.setType(type);

    //Starts  the web sockets
    serverEvents.setSessionId(nestingState.sessionId as string);

    //after 2 minute, if the export hasn't completed, stop the web sockets
    setTimeout(() => {
      if (serverEvents.connectionIsLive()) {
        serverEvents.dispose().then(() => {
          dispatch<any>(
            addToastMessage({
              id: guid(),
              severity: "danger",
              text: t("toastMessages.exportFailed"),
            })
          );
        });

        dispatch(setReportProgress(ReportProgressState.Idle));
      }
    }, 120000);
  };

export const exportRequest = createAsyncThunk(
  "nestingSlice/exportRequest",
  async (type: ExportType, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    const { nesting: nestingState } = thunkAPI.getState() as RootState;

    return await getClient()
      .then(async (client) => {
        return await client
          .post(
            new ExportRequest({
              type,
              nestingSessionId: nestingState.sessionId,
              resultId: nestingState.nestingResult?.id,
              exportName: nestingState.nestName,
            })
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const getCompletedExport = createAsyncThunk(
  "nestingSlice/GetCompletedExport",
  async (reportId: string, thunkAPI) => {
    const { getTokenRedirect } = thunkAPI.extra as {
      getTokenRedirect(msalInstance: PublicClientApplication): Promise<void | {
        token: string;
        isFromCache: boolean;
      }>;
    };

    const { nesting: nestingState } = thunkAPI.getState() as RootState;

    await getTokenRedirect(window.MsalInstance)
      .then(async (response) => {
        if (response === undefined) {
          throw new Error("No token");
        }

        // This uses our own download UI to show progress & handle errors =====================
        await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/json/reply/GetCompletedExport?reportId=${reportId}`,
          {
            headers: {
              authorization: `Bearer ${response.token}`,
            },
          }
        )
          .then(async (response) => {
            if (!response.ok) {
              throw new Error("Export failed");
            }

            thunkAPI.dispatch(
              setReportProgress(ReportProgressState.DownLoading)
            );
            const contentLength = response.headers.get("content-length") ?? "";
            const header = response.headers.get("Content-Disposition") ?? "";
            const total = parseInt(contentLength, 10);
            let loaded = 0;
            const res = new Response(
              new ReadableStream({
                async start(controller) {
                  const reader = response.body?.getReader();
                  for (;;) {
                    if (reader) {
                      const { done, value } = await reader.read();

                      if (done) break;
                      loaded += value.byteLength;
                      thunkAPI.dispatch(
                        setReportExportProgressBarVisibility(true)
                      );
                      thunkAPI.dispatch(setReportExportLoaded(loaded));
                      thunkAPI.dispatch(setReportExportTotal(total));

                      controller.enqueue(value);
                    }
                  }
                  controller.close();
                },
              })
            );

            const blob = await res.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");

            a.href = url;

            if (!header) {
              return thunkAPI.rejectWithValue("No content disposition header");
            }

            a.download = decodeURIComponent(
              // 1. Replace all '+' with '%20' in the string
              // 2. Split the string by ';' and take the 3rd element
              // 3. Match the string that starts with 'filename*=UTF-8''
              // 4. Take the last element in the array
              // 5. Decode the URI component
              header
                .replace(/\+/g, "%20")
                .toString()
                .split(";")[2]
                .match(/filename\*=UTF-8''(.+)/)!
                .slice(-1)[0]
            ).trim();

            document.body.appendChild(a);
            a.click();
            a.remove();

            thunkAPI.dispatch(
              setReportProgress(ReportProgressState.Downloaded)
            );
            thunkAPI.dispatch(
              fetchNotesForProject(nestingState.sessionId as string)
            );
            return blob;
          })
          .catch((error) => {
            thunkAPI.dispatch(setReportProgress(ReportProgressState.Error));
            thunkAPI.dispatch<any>(
              addToastMessage({
                id: guid(),
                severity: "danger",
                text: t("toastMessages.exportFailed"),
              })
            );
            return thunkAPI.rejectWithValue(error);
          })
          .finally(() => {
            setTimeout(() => {
              thunkAPI.dispatch(setReportProgress(ReportProgressState.Idle));
            }, 1000);
          });

        window.ServerEventReportInstance.dispose();
      })
      .catch((error) => {
        window.ServerEventReportInstance.dispose();
        return thunkAPI.rejectWithValue(error);
      });

    return true;
  }
);

export const fetchUserNestingSessionSettings = createAsyncThunk(
  "nestingSlice/fetchUserNestingSessionSettings",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .get(new UserNestingSessionSettingsRequest())

          .then((result: UserNestingSessionSettingsResponse) => {
            return result.settings;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchNestingResult = createAsyncThunk(
  "nestingSlice/fetchNestingResult",
  async ({ id, jobStatus }: { id: string; jobStatus: JobStatus }, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      const {
        nesting: { sessionId: projectId },
      } = thunkAPI.getState() as RootState;

      return await client
        .get(
          new FetchNestingResult({
            projectId,
            resultId: id,
            status: jobStatus,
          })
        )
        .then((result: NestResultSummary & { svg?: string }) => {
          result.svg = `${process.env.REACT_APP_API_BASE_URL}/NestingProject/Renders/Result/SVG?ResultId=${result?.id}&ProjectId=${projectId}`;

          const { partsToNest } = (thunkAPI.getState() as RootState).nesting;
          //Add unplaced part counts
          // map through partsToNest
          let parts = partsToNest.map((part) => {
            // return an object
            return {
              // containing the original part
              ...part,
              // and a partSettings object

              unPlaced:
                Object.entries(
                  // which is the result of Object.entries
                  result?.unplacedPartCounts ? result?.unplacedPartCounts : {}
                ).find(
                  // which is the result of find
                  (unPlaced) =>
                    // which is the result of the following condition
                    unPlaced[0] === part.nonLibraryPartId ||
                    unPlaced[0] === part.libraryId
                )?.[1] ?? 0,
            };
          });

          thunkAPI.dispatch(setParts(parts));

          thunkAPI.dispatch(setSessionDirty(false));

          return result;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        })
        .finally(() => {
          if (jobStatus === 4) {
            thunkAPI.dispatch(setNestingProgress(NestingProgressState.Idle));
          }
        });
    });
  }
);

/**
 * Cookie for images directly link to url - doesn't effect fetch url image
 * Fetch new "ImageAuthentication" cookie - times out after 30min
 * We can't check the value (secure cookie) so we just call a new one every time a part is added
 *
 *
 */
export const fetchImageCookie = createAsyncThunk(
  "nestingSlice/fetchImageCookie",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client.get(new SetNestingAuth()).catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
    });
  }
);

// also sets the "ImageAuthentication" cookie for the image fetch
export const nestingSessionRequest = createAsyncThunk(
  "nestingSlice/nestingSessionRequest",
  async (newNestingSessionRequest: NestingProjectStartNest, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(newNestingSessionRequest)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setNestingSessionProject = createAsyncThunk(
  "nestingSlice/setNestingSessionProject",

  async (newNestingSessionProject: SaveNestingProject, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .put(newNestingSessionProject)
          .then((nestingSession) => {
            return nestingSession;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const cancelNestingSession = createAsyncThunk(
  "nestingSlice/cancelNestingSession",

  async ({ sessionId, runId }: CancelNestingSession, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(new CancelNestingSession({ sessionId, runId }))
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const restoreProject = createAsyncThunk(
  "nestingSlice/restoreProject",
  async (
    {
      file,
      fileName,
      projectName,
      overwriteSettings,
      keepOriginalName,
    }: RestoreExistingProject & { keepOriginalName: boolean },
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .post(
            new RestoreExistingProject({
              file,
              fileName,
              projectName,
              overwriteSettings,
              keepOriginalName,
            })
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const nestingSlice = createSlice({
  initialState,
  name: "nestingSlice",

  reducers: {
    disposeNestingState: () => initialState,

    setIsExampleProject(state, action: PayloadAction<boolean>) {
      state.isExampleProject = action.payload;
    },

    setNestingFolderModalVisibility(state, action: PayloadAction<boolean>) {
      state.nestingFolderModalVisibility = action.payload;
    },

    setNestingActive(state, action: PayloadAction<boolean>) {
      state.nestingActive = action.payload;
    },

    setDisableSaveAndNest(state, action: PayloadAction<boolean>) {
      state.disableSaveAndNest = action.payload;
    },

    setActiveTab(state, action: PayloadAction<ActiveTab>) {
      state.activeTab = action.payload;
    },

    setNestingProgress(state, action: PayloadAction<NestingProgressState>) {
      state.nestingProgress = action.payload;
    },

    setReportProgress(state, action: PayloadAction<ReportProgressState>) {
      state.reportProgress = action.payload;
    },

    setReportExportProgressBarVisibility(
      state,
      action: PayloadAction<boolean>
    ) {
      state.reportExportProgressBarVisibility = action.payload;
    },

    setReportExportTotal(state, action: PayloadAction<number>) {
      state.reportExportTotal = action.payload;
    },

    setReportExportLoaded(state, action: PayloadAction<number>) {
      state.reportExportLoaded = action.payload;
    },

    setNestingSessionRequestErrorMessage(state, action: PayloadAction<any>) {
      state.nestingSessionRequestErrorMessage = action.payload;
    },
    //TODO: not used?
    setBusySaving(state, action: PayloadAction<boolean>) {
      state.busySaving = action.payload;
    },

    setNestName(state, action: PayloadAction<string>) {
      if (state.nestName !== action.payload) {
        state.sessionDirty = true;
      }
      state.nestName = action.payload;
    },

    setCurrentSettingsHasBeenNested(state, action: PayloadAction<boolean>) {
      state.currentSettingsHasBeenNested = action.payload;
    },

    setWebSocketsError(state, action: PayloadAction<string | null>) {
      state.webSocketsError = action.payload;
    },

    setSessionId(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
    },

    setRunId(state, action: PayloadAction<string>) {
      state.runId = action.payload;
    },

    setExistingProjectId(state, action: PayloadAction<string>) {
      state.existingProjectId = action.payload;
    },

    setNestingResult(state, action: PayloadAction<NestResultSummary | null>) {
      state.nestingResult = action.payload;
      state.sessionDirty = true;
    },
    //TODO: not used?
    setBusyFetchingSettings(state, action: PayloadAction<boolean>) {
      state.busyFetchingSettings = action.payload;
    },

    setSheetInputFocus(state, action: PayloadAction<boolean>) {
      state.sheetInputFocus = action.payload;
    },

    updateNestingPartsViaRotateOrMirror(
      state,
      action: PayloadAction<PartialDeep<NestablePart>[]>
    ) {
      const updatedParts = state.partsToNest.map((part) => {
        const updatedPart = action.payload.find((findPart) => {
          return findPart.id === part.id;
        });

        if (typeof updatedPart !== "undefined") {
          return {
            ...part,

            ...updatedPart,
            // set to new returned id
            id: updatedPart.nonLibraryPartId,
          } as NestablePart;
        } else {
          return part;
        }
      });

      state.partsToNest = [...updatedParts];
    },

    setParts(state, action: PayloadAction<PartialDeep<NestablePart>[]>) {
      const existingPartIds = state.partsToNest.map(
        (extantPart) => extantPart.id
      );

      //     Get a list of parts that have been updated .
      // For each part, update the part in the partsToNest array with the new values.

      const updatedParts = state.partsToNest.map((part) => {
        const updatedPart = action?.payload?.find((findPart) => {
          return findPart.id === part.id;
        });

        if (typeof updatedPart !== "undefined") {
          return {
            ...part,
            ...updatedPart,
          } as NestablePart;
        } else {
          return part;
        }
      });

      const addedParts = action.payload
        ? action.payload
            .filter(
              (newPart) =>
                typeof newPart.id === "undefined" ||
                !existingPartIds.includes(newPart.id)
            )
            .map(
              (newPart) =>
                ({
                  ...newPart,
                  fromLibrary: false,
                  localReference: newPart.id,
                } as NestablePart)
            )
        : [];

      state.partsToNest = [...updatedParts, ...addedParts];
    },

    setPartsLocalReference(
      state,
      action: PayloadAction<PartialDeep<NestablePart>[]>
    ) {
      const existingPartIds = state.partsToNest.map(
        (extantPart) => extantPart.localReference
      );

      //     Get a list of parts that have been updated .
      // For each part, update the part in the partsToNest array with the new values.

      const updatedParts = state.partsToNest.map((part) => {
        const updatedPart = action?.payload?.find((findPart) => {
          return findPart.localReference === part.localReference;
        });

        if (typeof updatedPart !== "undefined") {
          return {
            ...part,
            ...updatedPart,
          } as NestablePart;
        } else {
          return part;
        }
      });

      const addedParts = action.payload
        ? action.payload
            .filter(
              (newPart) =>
                typeof newPart.localReference === "undefined" ||
                !existingPartIds.includes(newPart.localReference)
            )
            .map(
              (newPart) =>
                ({
                  ...newPart,
                  fromLibrary: false,
                  localReference: newPart.id,
                } as NestablePart)
            )
        : [];

      state.partsToNest = [...updatedParts, ...addedParts];
    },

    removeParts(state, action: PayloadAction<Partial<NestablePart>[]>) {
      const partIdsToRemove = action.payload.map((part) => part.id);

      const removedParts = state.partsToNest.filter(
        (part) => !partIdsToRemove.includes(part.id)
      );

      state.partsToNest = removedParts;
      state.sessionDirty = true;
    },

    removeLocalReferenceParts(
      state,
      action: PayloadAction<Partial<NestablePart>[]>
    ) {
      const partLocalRefIdsToRemove = action.payload.map(
        (part) => part.localReference
      );

      const removedParts = state.partsToNest.filter(
        (part) => !partLocalRefIdsToRemove.includes(part.localReference)
      );

      state.partsToNest = removedParts;
      state.sessionDirty = true;
    },

    setSheets(state, action: PayloadAction<Partial<NestableSheet>[]>) {
      state.sheets = action.payload as NestableSheet[];
    },

    updateSheets(state, action: PayloadAction<Partial<NestableSheet>[]>) {
      //get existing sheet ids
      const existingSheetIds = state.sheets.map((sheet) => sheet.id);

      //update existing sheets
      const updatedSheets = state.sheets.map((sheet) => ({
        ...sheet,
        //find the new sheet that matches the existing sheet
        ...action.payload.find((newSheet) => newSheet.id === sheet.id),
      }));

      const addedSheets = action.payload
        ? action.payload
            .filter(
              (newSheet) =>
                typeof newSheet.id === "undefined" ||
                !existingSheetIds.includes(newSheet.id)
            )
            .map(
              (newSheet) =>
                ({
                  ...newSheet,
                  localReference: newSheet.id,
                } as NestableSheet)
            )
        : [];

      //add new sheets
      state.sheets = [
        //replace the existing sheets with any updated sheets
        ...updatedSheets,
        ...addedSheets,
      ];
    },

    setSheetsLocalReference(
      state,
      action: PayloadAction<Partial<NestableSheet>[]>
    ) {
      //get existing sheet ids
      const existingSheetIds = state.sheets.map(
        (sheet) => sheet.localReference
      );

      //update existing sheets
      const updatedSheets = state.sheets.map((sheet) => ({
        ...sheet,
        //find the new sheet that matches the existing sheet
        ...action.payload.find(
          (newSheet) => newSheet.localReference === sheet.localReference
        ),
      }));

      const addedSheets = action.payload
        ? action.payload
            .filter(
              (newSheet) =>
                typeof newSheet.localReference === "undefined" ||
                !existingSheetIds.includes(newSheet.localReference)
            )
            .map(
              (newSheet) =>
                ({
                  ...newSheet,
                  localReference: newSheet.id,
                } as NestableSheet)
            )
        : [];

      //add new sheets
      state.sheets = [
        //replace the existing sheets with any updated sheets
        ...updatedSheets,
        ...addedSheets,
      ];
    },

    removeSheets(state, action: PayloadAction<Partial<NestableSheet>[]>) {
      const sheetIdsToRemove = action.payload.map((sheet) => sheet.id);

      state.sheets = state.sheets.filter(
        (sheet) => !sheetIdsToRemove.includes(sheet.id)
      );
      state.sessionDirty = true;
    },

    setSettings(state, action: PayloadAction<Partial<NestingSetting[]>>) {
      const extantSettingNames = state.userNestingSessionSettings.map(
        (extantSetting) => extantSetting.name
      );

      const updatedSettings = state.userNestingSessionSettings.map(
        (extantSetting) => ({
          ...extantSetting,
          ...action.payload.find(
            (newSetting) => newSetting?.name === extantSetting.name
          ),
        })
      );

      state.userNestingSessionSettings = [
        ...updatedSettings,
        ...action.payload
          .filter(
            (newSetting) =>
              typeof newSetting?.name === "undefined" ||
              !extantSettingNames.includes(newSetting.name)
          )
          .map(
            (newSheet) =>
              ({
                ...newSheet,
              } as NestingSetting)
          ),
      ];
    },

    setSessionDirty(state, action: PayloadAction<boolean>) {
      state.sessionDirty = action.payload;
    },

    setCurrentNestSaved(state, action: PayloadAction<boolean>) {
      state.currentNestSaved = action.payload;
    },

    setInvalidSheetIds(state, action: PayloadAction<string[]>) {
      state.invalidSheetIds = action.payload;
    },

    setSelectedPartIds(state, action: PayloadAction<string[]>) {
      state.selectedPartIds = action.payload;
    },

    setSelectedSheetIds(state, action: PayloadAction<string[]>) {
      state.selectedSheetIds = action.payload;
    },

    setPartHoverId(state, action: PayloadAction<string>) {
      state.hoverPartId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // =======================================================================
      // GET: Preview SVG
      .addCase(fetchSVG.pending, (state) => {
        state.previewLoading = "pending";
        state.previewError = "";
      })
      .addCase(fetchSVG.fulfilled, (state, action) => {
        state.previewSVG = action.payload;
        state.previewLoading = "succeeded";
        state.previewError = "";
      })
      .addCase(fetchSVG.rejected, (state, rejectedAction) => {
        state.previewSVG = "";
        state.previewLoading = "failed";
        state.previewError = rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Fetch User Nesting Session Settings
      .addCase(fetchUserNestingSessionSettings.pending, (state) => {
        state.userNestingSessionSettingsLoading = "pending";
        state.userNestingSessionSettingsErrorMessage = "";
      })
      .addCase(fetchUserNestingSessionSettings.fulfilled, (state, action) => {
        state.userNestingSessionSettings = action.payload as NestingSetting[];
        state.userNestingSessionSettingsLoading = "succeeded";
        state.userNestingSessionSettingsErrorMessage = "";
      })
      .addCase(
        fetchUserNestingSessionSettings.rejected,
        (state, rejectedAction) => {
          state.userNestingSessionSettingsLoading = "failed";
          state.userNestingSessionSettingsErrorMessage =
            rejectedAction.payload as string;
        }
      )

      // =======================================================================
      // GET: Fetch Nesting Result
      .addCase(fetchNestingResult.pending, (state) => {
        state.nestingResultLoading = "pending";
        state.nestingResultErrorMessage = "";
      })
      .addCase(fetchNestingResult.fulfilled, (state, action) => {
        state.nestingResult = action.payload as
          | (NestResultSummary & { svg?: string })
          | null;
        state.nestingResultLoading = "succeeded";
        state.nestingResultErrorMessage = "";
      })
      .addCase(fetchNestingResult.rejected, (state, rejectedAction) => {
        state.nestingResultLoading = "failed";
        state.nestingResultErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // POST: Nesting Session Request
      .addCase(nestingSessionRequest.pending, (state) => {
        state.nestingSessionRequestResponse =
          initialState.nestingSessionRequestResponse;
        state.nestingSessionRequestLoading = "pending";
        state.nestingSessionRequestErrorMessage = "";
      })
      .addCase(nestingSessionRequest.fulfilled, (state, action) => {
        state.nestingSessionRequestResponse =
          action.payload as NestingProjectRunResponse;
        state.nestingSessionRequestLoading = "succeeded";
        state.nestingSessionRequestErrorMessage = "";
      })
      .addCase(nestingSessionRequest.rejected, (state, rejectedAction) => {
        state.nestingSessionRequestResponse =
          initialState.nestingSessionRequestResponse;
        state.nestingSessionRequestLoading = "failed";
        state.nestingSessionRequestErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Nesting Session Name
      .addCase(setNestingSessionProject.pending, (state) => {
        state.nestingSessionProjectResponse =
          initialState.nestingSessionProjectResponse;
        state.nestingSessionProjectLoading = "pending";
        state.nestingSessionProjectErrorMessage = "";
      })
      .addCase(setNestingSessionProject.fulfilled, (state, action) => {
        state.nestingSessionProjectResponse = action.payload as NestingProject;
        state.nestingSessionProjectLoading = "succeeded";
        state.nestName = action.payload.name;

        state.nestingSessionProjectErrorMessage = "";
      })
      .addCase(setNestingSessionProject.rejected, (state, rejectedAction) => {
        state.nestingSessionProjectResponse =
          initialState.nestingSessionProjectResponse;
        state.nestingSessionProjectLoading = "failed";
        state.nestingSessionProjectErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      // POST: Cancel Nesting Session
      .addCase(cancelNestingSession.pending, (state) => {
        state.cancelNestingSessionResponse =
          initialState.cancelNestingSessionResponse;
        state.cancelNestingSessionLoading = "pending";
        state.cancelNestingSessionErrorMessage = "";
      })
      .addCase(cancelNestingSession.fulfilled, (state, action) => {
        state.cancelNestingSessionResponse =
          action.payload as CancelNestingSessionResponse;
        state.cancelNestingSessionLoading = "succeeded";
        state.cancelNestingSessionErrorMessage = "";
      })
      .addCase(cancelNestingSession.rejected, (state, rejectedAction) => {
        state.cancelNestingSessionResponse =
          initialState.cancelNestingSessionResponse;
        state.cancelNestingSessionLoading = "failed";
        state.cancelNestingSessionErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      // POST: Restore Existing Project
      .addCase(restoreProject.pending, (state) => {
        state.restoreProjectLoading = "pending";
        state.restoreProjectError = "";
      })
      .addCase(restoreProject.fulfilled, (state) => {
        state.restoreProjectLoading = "succeeded";
        state.restoreProjectError = "";
      })
      .addCase(restoreProject.rejected, (state, rejectedAction) => {
        state.restoreProjectLoading = "failed";
        state.restoreProjectError = rejectedAction.payload as string;
      })

      // =======================================================================
      // GET:   Part Geometry For Ids
      .addCase(fetchPartGeometryForIds.pending, (state) => {
        state.partGeometryForIdsLoading = "pending";
      })
      .addCase(fetchPartGeometryForIds.fulfilled, (state) => {
        state.partGeometryForIdsLoading = "succeeded";
      })
      .addCase(fetchPartGeometryForIds.rejected, (state) => {
        state.partGeometryForIdsLoading = "failed";
      })

      // =======================================================================
      // POST:   Sheet Geometry For Settings
      .addCase(fetchSheetGeometryForSettings.pending, (state) => {
        state.sheetGeometryForSettingsLoading = "pending";
      })
      .addCase(fetchSheetGeometryForSettings.fulfilled, (state) => {
        state.sheetGeometryForSettingsLoading = "succeeded";
      })
      .addCase(fetchSheetGeometryForSettings.rejected, (state) => {
        state.sheetGeometryForSettingsLoading = "failed";
      });
  },
});

//generate new id - non library id from base64 part def - from api call
export const addOnTheFlySheetsToNest =
  (sheets: NestableSheet[]) => async (dispatch) => {
    const sheetData = sheets.map((sheet) => {
      return {
        ...sheet,
        importedSheet: true,
        id: sheet.libraryId || sheet.nonLibraryPartId,

        preview: sheet.svg,

        sheetDimensionXDisplay: sheet.xDimensionDisplay,
        sheetDimensionYDisplay: sheet.yDimensionDisplay,
      } as Partial<NestingSheet>;
    });

    dispatch(setSheetsLocalReference(sheetData));
    dispatch(setSessionDirty(true));
  };

export const addOnTheFlyPartsToNest =
  (parts: NestingPart[] | NestableSheet[]) => async (dispatch, getState) => {
    const userSettings = getState().settings.userSettings;

    const newParts: NestablePart[] = parts.map((part) => {
      //Override the default settings with the user settings

      return {
        ...part,
        name: part.name.trim()
          ? part.name
          : `${part.xDimensionDisplay}${measurementUnitShortString(
              MeasurementUnits[userSettings.measurementUnits]
            )} x ${part.yDimensionDisplay}${measurementUnitShortString(
              MeasurementUnits[userSettings.measurementUnits]
            )}`,
        id: part.libraryId ?? part.nonLibraryPartId ?? guid(),
        localReference: part.libraryId ?? part.nonLibraryPartId ?? guid(),
        fromLibrary: false,
        isLibraryPart: part.isLibraryPart,

        isDeleted: false,
        isSelected: true,
        busy: false,
        active: false,

        allowMirror: userSettings.mirrorDefault,
        allowedRotation: userSettings.defaultAllowedRotations,
        tilt: userSettings.defaultTilt,

        unPlaced: 0,
        svg: part.base64Svg ?? part.preview,

        //
        createdByUserId: "",
        createdDate: "",
        sourceFileId: "",
      };
    });

    dispatch(setParts(newParts));
    dispatch(setSessionDirty(true));
  };

export const abortNesting = (): AppThunk => async (dispatch, getState) => {
  const sessionId = getState().nesting.sessionId;
  const runId = getState().nesting.runId;
  const nestingProgressState = getState().nesting.nestingProgress;

  dispatch(
    cancelNestingSession({ sessionId, runId } as CancelNestingSession)
  ).then(() => {
    dispatch(fetchSavedNests({}));
    dispatch(fetchRecentNests());
  });

  //NestingProgressState.Active = signalR status === 3("ResultGenerated") & status === 1("AgentAssigned")
  if (nestingProgressState !== NestingProgressState.Active) {
    window.ServerEventInstance.dispose()
      .then(() => {
        dispatch(setNestingProgress(NestingProgressState.Idle));
        dispatch(setSessionDirty(false));
        //Set start nesting button back to un-disabled
        dispatch(setSessionDirty(true));
      })
      .catch(() => {});
  }
};

export const resetNesting = (): AppThunk => async (dispatch) => {
  dispatch(disposeNestingState());
  dispatch(fetchUserSettings());
  dispatch(fetchUserNestingSessionSettings());
  dispatch(clearNotes());

  window.requestAnimationFrame(() => {
    dispatch(setSessionDirty(false));
  });
};

export const rehydrateNest =
  (nestProject: NestingProject) => async (dispatch, getState) => {
    const userSettings = getState().settings.userSettings;

    const decimals =
      MeasurementUnits[userSettings.measurementUnits].toString() ===
      MeasurementUnits.Millimetres.toString()
        ? 2
        : 3;

    let parts = nestProject.parts.map((part) => {
      return {
        ...part,
        id: part.libraryId ?? part.nonLibraryPartId ?? "",
        localReference: part.libraryId ?? part.nonLibraryPartId ?? "",

        unPlaced:
          Object.entries(
            nestProject?.lastOrDefinitiveResultSummary?.unplacedPartCounts
              ? nestProject?.lastOrDefinitiveResultSummary?.unplacedPartCounts
              : {}
          ).find((unPlaced) => {
            return (
              unPlaced[0] === part.libraryId ||
              unPlaced[0] === part.nonLibraryPartId
            );
          })?.[1] ?? 0,

        svg:
          part.libraryId || part.nonLibraryPartId
            ? `${
                process.env.REACT_APP_API_BASE_URL
              }/NestingProject/Renders/Part/SVG?PartId=${
                part.libraryId ?? part.nonLibraryPartId ?? ""
              }&ProjectId=${nestProject.id}`
            : "",
      } as NestablePart;
    });

    const sheets = nestProject?.sheets.map(
      (sheetPayload) =>
        ({
          ...sheetPayload,
          id: sheetPayload.libraryId ?? sheetPayload.nonLibraryPartId ?? "",

          sheetDimensionXDisplay: Number(
            sheetPayload.xDimensionDisplay?.toFixed(decimals)
          ),
          sheetDimensionYDisplay: Number(
            sheetPayload.yDimensionDisplay?.toFixed(decimals)
          ),

          preview: `${
            process.env.REACT_APP_API_BASE_URL
          }/NestingProject/Renders/Sheet/SVG?SheetId=${
            sheetPayload.libraryId ?? sheetPayload.nonLibraryPartId
          }&ProjectId=${nestProject?.id}&Height=30&Width=30`,
        } as Partial<NestableSheet>)
    );

    //Set Project Id
    dispatch(setSessionId(nestProject?.id ? nestProject?.id : ""));
    dispatch(setRunId(""));

    dispatch(setParts(parts));

    dispatch(updateSheets(sheets));
    dispatch(setNestName(nestProject.name));

    dispatch(
      setNestingResult({
        ...nestProject.lastOrDefinitiveResultSummary,
        svg: nestProject?.lastOrDefinitiveResultSummary
          ? `${process.env.REACT_APP_API_BASE_URL}/NestingProject/Renders/Result/SVG?ResultId=${nestProject?.lastOrDefinitiveResultSummary?.id}&ProjectId=${nestProject?.id}`
          : null,
      } as NestResultSummary & { svg: string })
    );

    dispatch(
      setCurrentSettingsHasBeenNested(nestProject.currentSettingsHasBeenNested)
    );
    //fetch nest setting defaults
    dispatch(fetchUserNestingSessionSettings())
      .unwrap()
      .then((settings: NestingSetting[]) => {
        //     override with rehydrateNest settings

        let rehydratedSettings = nestProject?.nestSettings;

        let newSettings = settings.map((setting) => {
          return {
            ...setting,
            value: rehydratedSettings[setting.propertyName],
          };
        });
        //override,

        dispatch(setSettings(newSettings));
      })
      .catch(() => {
        // could be 503 from maintenance mode
      });
    //

    window.requestAnimationFrame(() => {
      dispatch(setSessionDirty(false));
    });
  };

export const addLibraryPartsToNest =
  (partsToAdd: NestablePart[]) => async (dispatch, getState) => {
    const state = getState() as RootState;
    const currentParts = state.nesting.partsToNest;

    const userSettings = state.settings.userSettings;

    const extantPartIds = currentParts.map((extantPart) => extantPart.id);

    const newParts: NestablePart[] = partsToAdd
      .filter((newPart) => {
        const alreadyUsed = extantPartIds.includes(newPart.id);

        if (alreadyUsed) {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "info",
              text: t("toastMessages.partAlreadyAddedError", {
                name: newPart.name,
              }),
              timeout: 15000,
            })
          );
        }

        return !alreadyUsed;
      })
      .map((part) => {
        return {
          ...part,
          isLibraryPart: true,

          localReference: part.id,

          allowMirror: userSettings.mirrorDefault,
          allowedRotation: userSettings.defaultAllowedRotations,

          priority: 0,
          quantity: 1,
          tilt: userSettings.defaultTilt,
        } as NestablePart;
      });

    dispatch(setParts([...newParts]));
    dispatch(setSessionDirty(true));
  };

export const startNest = (): AppThunk => async (dispatch, getState) => {
  dispatch(setNestingResult(null));

  const sessionId = getState().nesting.sessionId ?? "";

  const folderId = getState().nests.selectedNestFolderId ?? "";

  const name = getState().nesting.nestName;

  const nestingState = getState().nesting;

  if (nestingState.nestingResult === null) {
    dispatch(setSessionDirty(true));
    dispatch(setNestingProgress(NestingProgressState.Uploading));

    const partsFiltered = nestingState?.partsToNest?.map(
      ({ base64Svg, svg, ...rest }) => {
        return {
          base64Svg: "",
          ...rest,
        } as NestablePart;
      }
    );

    const sheetsFiltered = nestingState?.sheets?.map(
      ({ base64Svg, svg, ...rest }) => {
        return {
          base64Svg: "",
          ...rest,
        } as NestableSheet;
      }
    );

    dispatch(
      nestingSessionRequest(
        new NestingProjectStartNest({
          folderId,
          id: nestingState.isExampleProject ? "" : sessionId,

          parts: partsFiltered ?? [],
          sheets: sheetsFiltered ?? [],
          nestSettings: {
            nestingDirection: 0,
            nestingRepeatType: Multiplicity.BestEfficiency,
            partSpacingDisplay: Number(
              nestingState.userNestingSessionSettings.find(
                (setting) => setting.propertyName === "partSpacingDisplay"
              )?.value ?? 0
            ),
            sheetSpacingDisplay: Number(
              nestingState.userNestingSessionSettings.find(
                (setting) => setting.propertyName === "sheetSpacingDisplay"
              )?.value ?? 0
            ),
            leftSheetSpacingDisplay: Number(
              nestingState.userNestingSessionSettings.find(
                (setting) => setting.propertyName === "leftSheetSpacingDisplay"
              )?.value ?? 0
            ),
            topSheetSpacingDisplay: Number(
              nestingState.userNestingSessionSettings.find(
                (setting) => setting.propertyName === "topSheetSpacingDisplay"
              )?.value ?? 0
            ),
            rightSheetSpacingDisplay: Number(
              nestingState.userNestingSessionSettings.find(
                (setting) => setting.propertyName === "rightSheetSpacingDisplay"
              )?.value ?? 0
            ),
            bottomSheetSpacingDisplay: Number(
              nestingState.userNestingSessionSettings.find(
                (setting) =>
                  setting.propertyName === "bottomSheetSpacingDisplay"
              )?.value ?? 0
            ),
            showOnlyUniqueNests: true,
            //   selectedAreaMeasurementUnits: userSettings.areaMeasurementUnit,
            // selectedMeasurementUnits: userSettings.measurementUnits,
          },

          name: name,
          //Not set
          // currentSettingsHasBeenNested: false,
          //    lastResult: new NestingResult(),
        })
      )
    )
      .unwrap()
      .then((response) => {
        //no longer example project
        dispatch(setIsExampleProject(false));
        //"Not Nested" goes into Saved Nests
        dispatch(setNestingProgress(NestingProgressState.AwaitingWorker));

        dispatch(setSessionId(response.projectId));
        dispatch(setRunId(response.runId));

        if (response.hasNestRunningAlready) {
          dispatch(setNestingProgress(NestingProgressState.Error));
          dispatch(setRunId(""));

          //spoof a error code exception message
          dispatch(
            setNestingSessionRequestErrorMessage({
              responseStatus: { errorCode: "HasNestRunningAlready" },
            })
          );
          return;
        }

        if (response.hasEnoughAllowance === false) {
          dispatch(setNestingProgress(NestingProgressState.AllowanceReached));
          dispatch(setSessionDirty(false));

          return;
        }

        //Signalr connect
        const serverEvents = new ServerEventsNesting();
        //Attach instance to window so we can abort
        window.ServerEventInstance = serverEvents;
        if (serverEvents !== null && response.projectId.length > 0) {
          serverEvents.setSessionId(response.runId);

          serverEvents.setDispatch(dispatch);
          serverEvents.setGetState(getState);
        }
        window.requestAnimationFrame(() => {
          dispatch(setSessionDirty(false));
        });
      })
      .catch(() => {
        // error ProjectId is set via a header

        dispatch(
          fetchSavedNests({ folderId, searchType: FolderSearchType.Subfolders })
        );
        dispatch(fetchRecentNests());
        dispatch(setNestingProgress(NestingProgressState.Error));
        dispatch(setSessionDirty(false));
      });
  }
};

export const {
  disposeNestingState,
  removeLocalReferenceParts,
  removeParts,
  removeSheets,
  setActiveTab,
  setBusyFetchingSettings,
  setBusySaving,
  setCurrentNestSaved,
  setCurrentSettingsHasBeenNested,
  setDisableSaveAndNest,
  setInvalidSheetIds,
  setIsExampleProject,
  setNestName,
  setNestingActive,
  setNestingFolderModalVisibility,
  setNestingProgress,
  setNestingResult,
  setNestingSessionRequestErrorMessage,
  setPartHoverId,
  setParts,
  setPartsLocalReference,
  setReportExportLoaded,
  setReportExportProgressBarVisibility,
  setReportExportTotal,
  setReportProgress,
  setRunId,
  setSelectedPartIds,
  setSelectedSheetIds,
  setSessionDirty,
  setSessionId,
  setSettings,
  setSheetInputFocus,
  setSheets,
  setSheetsLocalReference,
  setWebSocketsError,
  updateNestingPartsViaRotateOrMirror,
  updateSheets,
} = nestingSlice.actions;

export default nestingSlice.reducer;

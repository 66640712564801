import "./css/main.css";
import "./language/i18next";

import {
  EventMessage,
  EventMessageUtils,
  EventType,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";

import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import ServerEventsImporter from "./serviceClient/ESServiceClient/ServerEventsImporter";
import ServerEventsNesting from "./serviceClient/ESServiceClient/SeverEventsNesting";
import ServerEventsReport from "./serviceClient/ESServiceClient/ServerEventsReport";
import cookie from "react-cookies";
import { createRoot } from "react-dom/client";
import jsonConfig from "./app/config.json";
import { msalConfig } from "./msalConfig";
import rg4js from "raygun4js";
import store from "./app/store";

rg4js("apiKey", decodeURI(jsonConfig.Raygun.apiKey));
rg4js("enableCrashReporting", jsonConfig.Raygun.enableCrashReporting);
if (jsonConfig.Raygun.enablePulse) {
  rg4js("enablePulse", jsonConfig.Raygun.enablePulse);
}
rg4js("setVersion", `MyNesting ${process.env.REACT_APP_BUILD_TYPE}`);
rg4js("logContentsOfXhrCalls", true);
rg4js("options", { ignore3rdPartyErrors: true });

// rg4js("options", {
//   debugMode: process.env.REACT_APP_BUILD_TYPE !== "production",
// });

if (process.env.REACT_APP_BUILD_TYPE !== "production") {
  rg4js("disableAutoBreadcrumbsConsole");
}

rg4js("send", {
  tags: [
    "root",
    (cookie.load("DARK-MODE") === "true" ? "dark_theme" : "light_theme") ??
      "light_theme",
  ],
});

declare global {
  interface Window {
    Paddle?: any;
    ServerEventInstance: ServerEventsNesting;
    ServerEventImporterInstance: ServerEventsImporter;
    ServerEventReportInstance: ServerEventsReport;
    MsalInstance: PublicClientApplication;
    MsalInteractionStatus: InteractionStatus | null;
  }
}

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
  
 */

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((message: EventMessage) => {
  const status = EventMessageUtils.getInteractionStatusFromEvent(message);

  window.MsalInteractionStatus = status;

  if (message.eventType === EventType.LOGIN_FAILURE) {
    msalInstance.loginRedirect();
  }
});

window.MsalInstance = msalInstance;

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      {/* Renders components twice */}
      {/* Strict mode causes paddle to reload twice */}
      {/* <StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </StrictMode> */}
    </Provider>
  </MsalProvider>
);

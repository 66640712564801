import { ParametricPartType } from "../../../serviceClient/api.dtos";
import { guid } from "../../../Util";
import { useTranslation } from "react-i18next";

export type PropsOrderType = {
  [key in ParametricPartType]: string[];
};

//Order of keys used for sidebar - properties for api call
export const PROPSORDER: PropsOrderType = {
  [ParametricPartType.RectangleXY]: ["prop1", "prop2"],
  [ParametricPartType.CircleRadius]: ["prop1"],
  [ParametricPartType.RingOuterRadiusInnerRadius]: ["prop1", "prop2"],
};

export const shapeValidation = {
  validateNegativeNumber: (value: any): string => {
    //return true

    const isValid = Number(value) >= 0;
    return isValid ? "" : "invalidNumber";
  },
  validateNoDecimal: (value: any): string => {
    //return true

    const isValid = Number.isInteger(Number(value));
    return isValid ? "" : "invalidNumber";
  },
};

export class ShapeClass {
  name: string;
  prop1: string;
  prop2?: string;
  quantity: string;
  type: ParametricPartType;
  identity: string;
  errors: { name: string; prop1: string; prop2?: string; quantity: string };
  inValid: boolean;

  // name: string = "";
  // prop1: string = "";
  // prop2?: string = "";
  // quantity: string = "";
  // type: ParametricPartType = ParametricPartType.RectangleXY;
  // identity: string = guid();
  // errors: {
  //   name: string;
  //   prop1: string;
  //   prop2?: string;
  //   quantity: string;
  // } = { name: "", prop1: "", prop2: "", quantity: "" };
  // inValid: boolean = false;

  constructor(data?: Partial<ShapeClass>) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.name = "";
      this.prop1 = "";
      this.prop2 = "";
      this.quantity = "";
      this.type = ParametricPartType.RectangleXY;
      this.identity = guid();
      this.errors = { name: "", prop1: "", prop2: "", quantity: "" };
      this.inValid = false;
    }

    // name: string = "",
    // prop1: string = "",
    // prop2: string = "",
    // quantity: string = "",
    // type: ParametricPartType = ParametricPartType.RectangleXY,
    // identity: string = guid(),
    // errors: {
    //   name: string;
    //   prop1: string;
    //   prop2?: string;
    //   quantity: string;
    // } = { name: "", prop1: "", prop2: "", quantity: "" },
    // inValid: boolean = false
    //)
    //{
    // this.name = name;
    // this.prop1 = prop1;
    // this.prop2 = prop2;
    // this.quantity = quantity;
    // this.type = type;
    // this.identity = identity;
    // this.errors = errors;
    // this.inValid = inValid;
  }
  // the tab type will define the type of the shape
  setType(type: ParametricPartType) {
    this.type = type;

    PROPSORDER[type].forEach((prop) => {
      // if prop is not in the order list, delete it
      if (!Object.keys(this).includes(prop)) {
        delete this[prop];
      }
    });

    return this; // Return the instance to allow chaining
  }
  isCompletedShape() {
    //Does this shape have all the required properties?
    const { type, identity, errors, inValid, ...filtered } = this;
    return Object.values(filtered).every((value) => value);
  }
  isEmptyShape() {
    //Does this shape have no properties values?
    const { type, identity, errors, inValid, ...filtered } = this;

    return !Object.values(filtered).every((value) => value === "");
  }

  isPartiallyCompletedShape() {
    //Does this shape have some properties values?
    //filter type and identity and errors from this check

    const { type, identity, errors, inValid, ...filtered } = this;

    return Object.values(filtered).some((value) => value);
  }
  isValid(shape: Partial<ShapeClass>) {
    const existingErrors = shape.errors;

    // Reset required if all other fields are empty
    if (this.isEmptyShape()) {
      this.errors.name = "";
    }
    console.log("isValid", existingErrors);
    if (!this.name && existingErrors?.name === "required") {
      this.errors.name = "required";
    }

    if (!Number.isInteger(Number(this.quantity)) || Number(this.quantity) < 0) {
      this.errors.quantity = "invalidNumber";
    } else {
      this.errors.quantity = "";
    }

    // no negative prop1 or prop2 or zero
    if (this.prop1 && Number(this.prop1) < 1) {
      this.errors.prop1 = "invalidNumber";
    } else {
      this.errors.prop1 = "";
    }

    if (this.prop2 && Number(this.prop2) < 1) {
      this.errors.prop2 = "invalidNumber";
    } else if (this.type === ParametricPartType.RingOuterRadiusInnerRadius) {
      //check outer is greater than inner
      if (parseInt(this.prop1) <= parseInt(this.prop2 ?? "0")) {
        this.errors.prop2 = "innerGreaterThanOuter";
      } else {
        this.errors.prop2 = "";
      }
    } else {
      this.errors.prop2 = "";
    }
    //if any errors return true

    return (this.inValid = Object.values(this.errors).some((value) => value));
  }

  submitValidation() {
    //highlight all empty fields

    if (this.isPartiallyCompletedShape()) {
      if (!this.name) {
        this.errors.name = "required";
      } else {
        this.errors.name = "";
      }

      if (!this.prop1) {
        this.errors.prop1 = "required";
      } else {
        this.errors.prop1 = "";
      }
      if (this.type !== ParametricPartType.CircleRadius) {
        if (!this.prop2) {
          this.errors.prop2 = "required";
        } else {
          this.errors.prop2 = "";
        }
      }
      if (!this.quantity) {
        this.errors.quantity = "required";
      } else if (
        !Number.isInteger(Number(this.quantity)) ||
        Number(this.quantity) < 0
      ) {
        this.errors.quantity = "invalidNumber";
      } else {
        this.errors.quantity = "";
      }
    }

    return (this.inValid = Object.values(this.errors).some((value) => value));
  }
}

export type ShapeHeader = {
  id: string;
  propertyName: string;
  header: string;
};

export type ShapesHeader = {
  CircleRadius: ShapeHeader[];
  RectangleXY: ShapeHeader[];
  RingOuterRadiusInnerRadius: ShapeHeader[];
};

export function useShapesHeaders() {
  const { t } = useTranslation();

  const shapesHeader: ShapesHeader = {
    RectangleXY: [
      {
        id: "1",
        propertyName: "name",
        header: t("createShapes.table.name"),
      },
      {
        id: "2",
        propertyName: "prop1",
        header: t("createShapes.table.xDim"),
      },
      {
        id: "3",
        propertyName: "prop2",
        header: t("createShapes.table.yDim"),
      },
      {
        id: "4",
        propertyName: "quantity",
        header: t("createShapes.table.quantity"),
      },
    ],

    CircleRadius: [
      {
        id: "1",
        propertyName: "name",
        header: t("createShapes.table.name"),
      },
      {
        id: "2",
        propertyName: "prop1",
        header: t("createShapes.table.radius"),
      },
      {
        id: "3",
        propertyName: "quantity",
        header: t("createShapes.table.quantity"),
      },
    ],

    RingOuterRadiusInnerRadius: [
      {
        id: "1",
        propertyName: "name",
        header: t("createShapes.table.name"),
      },
      {
        id: "2",
        propertyName: "prop1",
        header: t("createShapes.table.outerRadius"),
      },
      {
        id: "3",
        propertyName: "prop2",
        header: t("createShapes.table.innerRadius"),
      },
      {
        id: "4",
        propertyName: "quantity",
        header: t("createShapes.table.quantity"),
      },
    ],
  };

  return shapesHeader;
}

export function shapesHaveValues(shape: ShapeClass) {
  //check if main properties have values
  const { errors, inValid, ...filteredShape } = shape;

  if (filteredShape.type === ParametricPartType.CircleRadius) {
    delete shape.prop2;
  }

  console.log("shapesHaveValues", filteredShape);

  return Object.values(filteredShape).every((value) => value);
}

// orderedDimensions property for api call
export function getOrderedDimensions(shape: ShapeClass) {
  return PROPSORDER[shape.type].map((prop) => shape[prop]);
}

import {
  AreaMeasurementUnits,
  AutoCadExportSettings,
  CameraBehaviour,
  DarkModeSettings,
  DxfVersionValues,
  GetDarkModeForUser,
  InitialSettingsUpdateRequest,
  MeasurementUnits,
  NamingScheme,
  NestExportSettings,
  NestingDirectionSetting,
  PartExportSettings,
  RepeatOption,
  ReportSizes,
  Rotations,
  SettingsRequest,
  SettingsUpdateRequest,
  TeamSheets,
  UpdateDarkModeForUser,
  UpdateImporterGlobalSettings,
  UserSettingsResponse,
} from "../../serviceClient/api.dtos";
import { NestableSheet, updateSheets } from "../nesting/nestingSlice";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../common/commonTypes";
import { RootState } from "../../app/store";
import cookie from "react-cookies";

type SettingsTab = "Application" | "Nesting" | "Import" | "Export" | "All";

type SettingsState = {
  busyFetchingSettings: boolean;
  darkMode: boolean;
  darkModeErrorMessage: string;
  darkModeLoading: Loading;
  errorMessage: string;
  loading: Loading;
  saveUserSettingsLoading: Loading;
  settingsModalShown: boolean;
  settingsModalVisibility: boolean;
  settingsTab: SettingsTab;
  userSettings: UserSettingsResponse;
};

const defaultUserSettings = {
  areaMeasurementUnits: AreaMeasurementUnits.MillimeterSquared,
  autoCadSettings: {} as AutoCadExportSettings,
  defaultAllowedRotations: Rotations.Any,
  defaultBottomSheetSpacing: 0,
  defaultBottomSheetSpacingDisplay: 0,
  defaultLeftSheetSpacing: 0,
  defaultLeftSheetSpacingDisplay: 0,
  defaultNamingScheme: NamingScheme.Filename,
  defaultPartRotationId: 0,
  defaultPartSpacing: 0,
  defaultPartSpacingDisplay: 0,
  defaultRightSheetSpacing: 0,
  defaultRightSheetSpacingDisplay: 0,
  defaultSheetQuantity: 1,
  defaultSheetSpacing: 1,
  defaultSheetSpacingDisplay: 0,
  defaultTeamSheets: [] as TeamSheets[],
  defaultTilt: 0,
  defaultTopSheetSpacing: 0,
  defaultTopSheetSpacingDisplay: 0,
  dxfOptions: [] as DxfVersionValues[],
  importBlocksIndependently: false,
  initialSetupComplete: false,
  labelHeightDisplay: 0,
  maxFileUploads: 100,
  maxFilesizeMb: 0,
  measurementUnits: MeasurementUnits.Millimetres,
  mirrorDefault: false,
  nestingDirection: NestingDirectionSetting.East,
  nestingRepeat: RepeatOption.BestEfficiency,
  nestingSettings: {} as NestExportSettings,
  panZoomMode: CameraBehaviour.MoveWork,
  partSettings: {} as PartExportSettings,
  useSeparateSheetSpacing: true,
  reportWithAllExports: true,
  pdfReportPageSize: "A4",
  availableReportSizesToTheUser: [] as ReportSizes[],
} as UserSettingsResponse;

const initialState: SettingsState = {
  busyFetchingSettings: false,
  darkMode: cookie.load("DARK-MODE") === "true" ?? false,
  darkModeErrorMessage: "",
  darkModeLoading: "idle",
  errorMessage: "",
  loading: "idle",
  saveUserSettingsLoading: "idle",
  settingsModalShown: false,
  settingsModalVisibility: false,
  settingsTab: "All",
  userSettings: defaultUserSettings,
};

export const updateImporterGlobalSettings = createAsyncThunk(
  "settingsSlice/updateImporterGlobalSettings",
  async (sessionId: Partial<UpdateImporterGlobalSettings>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(new UpdateImporterGlobalSettings(sessionId))
        .then((data) => {
          return data;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchUserSettings = createAsyncThunk(
  "settingsSlice/fetchUserSettings",
  async (_, thunkAPI) => {
    //
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    const {
      nesting: { sessionDirty },
    } = thunkAPI.getState() as RootState;

    return await getClient().then(async (client) => {
      return await client
        .get(new SettingsRequest())
        .then((data) => {
          // =============================================
          //Set Default Team sheets into sheets state for New Nests
          //cast DefaultTeamSheet to NestableSheet

          const decimals =
            MeasurementUnits[data.measurementUnits].toString() ===
            MeasurementUnits.Millimetres.toString()
              ? 2
              : 3;

          let defaultSheets: NestableSheet[] = [];
          data.defaultTeamSheets.forEach((defaultTeamSheet) => {
            let defaultSheet = {
              isLibraryPart: true,
              id: defaultTeamSheet.id,

              name: defaultTeamSheet.name,
              nonLibraryPartId: defaultTeamSheet.id,
              quantity: defaultTeamSheet.quantity,
              sheetDimensionXDisplay: defaultTeamSheet.sheetDimensionXDisplay
                ? defaultTeamSheet.sheetDimensionXDisplay.toFixed(decimals)
                : 0,
              sheetDimensionYDisplay: defaultTeamSheet.sheetDimensionYDisplay
                ? defaultTeamSheet.sheetDimensionYDisplay.toFixed(decimals)
                : 0,
              priority: defaultTeamSheet.priority,

              leftSpacingDisplay: 0,
              rightSpacingDisplay: 0,
              topSpacingDisplay: 0,
              bottomSpacingDisplay: 0,
            } as NestableSheet;
            defaultSheets.push(defaultSheet);
          });

          // Do not update sheets if the session is dirty

          if (!sessionDirty) {
            thunkAPI.dispatch(updateSheets(defaultSheets));
          }
          return data;
        })
        .catch((error) => {
          console.error("fetchUserSettings error", error);
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const saveInitialUserSettings = createAsyncThunk(
  "settingsSlice/saveInitialUserSettings",
  async (measurementUnits: Partial<UserSettingsResponse>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(new InitialSettingsUpdateRequest(measurementUnits))
        .then((data) => {
          return data;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const saveUserSettings = createAsyncThunk(
  "settingsSlice/saveUserSettings",
  async (
    {
      fieldName,
      newUserSettings,
    }: { fieldName: string; newUserSettings: UserSettingsResponse },
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(new SettingsUpdateRequest(newUserSettings))
        .then((data) => {
          const filterName = fieldName.split(".");
          const {
            settings: { userSettings },
          } = thunkAPI.getState() as RootState;

          const {
            importer: {
              session: { sessionId },
            },
          } = thunkAPI.getState() as RootState;

          let newState = userSettings;

          if (filterName.length === 1) {
            newState = {
              ...userSettings,
              [filterName[0]]: data[filterName[0]],
            };
          } else if (filterName.length === 2) {
            newState = {
              ...userSettings,
              [filterName[0]]: {
                ...userSettings?.[filterName[0]],
                [filterName[1]]: data[filterName[0]][filterName[1]],
              },
            };
          }

          //in import session only
          if (sessionId) {
            ////UpdateImporterGlobalSettings
            thunkAPI.dispatch(
              updateImporterGlobalSettings({
                sessionId: sessionId,
              })
            );
          }

          //measurementUnits changes 2 properties in the back end
          return fieldName === "measurementUnits" ? data : newState;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchIsDarkMode = createAsyncThunk(
  "commonSlice/fetchThemeType",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetDarkModeForUser())
        .then((data) => {
          return data.isDarkMode;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const saveIsDarkMode = createAsyncThunk(
  "commonSlice/saveIsDarkMode",
  async ({ isDarkMode }: DarkModeSettings, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(new UpdateDarkModeForUser({ isDarkMode }))
        .then((data) => {
          return data.isDarkMode;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

const settingsSlice = createSlice({
  initialState,
  name: "settingsSlice",
  reducers: {
    setDarkMode(state, action: PayloadAction<boolean>) {
      state.darkMode = action.payload;
    },
    setSettingsModalShown(state, action: PayloadAction<boolean>) {
      state.settingsModalShown = action.payload;
    },
    setSettingsModalVisibility(state, action: PayloadAction<boolean>) {
      state.settingsModalVisibility = action.payload;
    },
    setSettingsTab(state, action: PayloadAction<SettingsTab>) {
      state.settingsTab = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // =======================================================================
      //PUT: Save User Initial Settings
      .addCase(saveInitialUserSettings.pending, (state) => {
        state.saveUserSettingsLoading = "pending";
        state.errorMessage = "";
      })
      .addCase(saveInitialUserSettings.fulfilled, (state, action) => {
        state.userSettings = action.payload as UserSettingsResponse;
        state.saveUserSettingsLoading = "succeeded";
        state.errorMessage = "";
      })
      .addCase(saveInitialUserSettings.rejected, (state, rejectedAction) => {
        state.saveUserSettingsLoading = "failed";
        state.errorMessage = rejectedAction.payload as string;
      })
      // =======================================================================
      //PUT: Save User Settings
      .addCase(saveUserSettings.pending, (state) => {
        state.saveUserSettingsLoading = "pending";
        state.errorMessage = "";
      })
      .addCase(saveUserSettings.fulfilled, (state, action) => {
        state.userSettings = action.payload as UserSettingsResponse;
        state.saveUserSettingsLoading = "succeeded";
        state.errorMessage = "";
      })
      .addCase(saveUserSettings.rejected, (state, rejectedAction) => {
        state.saveUserSettingsLoading = "failed";
        state.errorMessage = rejectedAction.payload as string;
      })
      // =======================================================================
      // GET: Fetch User Settings
      .addCase(fetchUserSettings.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        state.userSettings = action.payload as UserSettingsResponse;
        state.loading = "succeeded";
        state.errorMessage = "";
      })
      .addCase(fetchUserSettings.rejected, (state, rejectedAction) => {
        state.loading = "failed";
        state.errorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Fetch is Dark Mode
      .addCase(fetchIsDarkMode.pending, (state) => {
        state.darkModeLoading = "pending";
      })
      .addCase(fetchIsDarkMode.fulfilled, (state, action) => {
        state.darkMode = action.payload as boolean;
        state.darkModeLoading = "succeeded";
        state.darkModeErrorMessage = "";

        cookie.save("DARK-MODE", action.payload, {
          secure: false,
          sameSite: "strict",
          path: "/",
        });
      })
      .addCase(fetchIsDarkMode.rejected, (state, rejectedAction) => {
        state.darkModeLoading = "failed";
        state.darkModeErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Update is Dark Mode
      .addCase(saveIsDarkMode.pending, (state) => {
        state.darkModeLoading = "pending";
      })
      .addCase(saveIsDarkMode.fulfilled, (state, action) => {
        state.darkMode = action.payload as boolean;

        state.darkModeLoading = "succeeded";
        state.darkModeErrorMessage = "";

        cookie.save("DARK-MODE", action.payload, {
          secure: false,
          sameSite: "strict",
          path: "/",
        });
      })
      .addCase(saveIsDarkMode.rejected, (state, rejectedAction) => {
        state.darkModeLoading = "failed";
        state.darkModeErrorMessage = rejectedAction.payload as string;
      });
  },
});

export const {
  setDarkMode,
  setSettingsModalVisibility,
  setSettingsTab,
  setSettingsModalShown,
} = settingsSlice.actions;

export default settingsSlice.reducer;
